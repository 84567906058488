body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.student-row {
  margin-bottom: 0rem; 
}

.compact-hr {
  margin: 0rem 0;
  border: 0;
  height: 3px; 
  background-color: #000075; 
  width: 100%; 
  opacity: 1;
}

.notification-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-item:hover {
  background-color: #f0f0f0;
}

.payment-method {
  border: 2px solid transparent;
}

.payment-method.selected {
  border: 2px solid #007bff; /* ou la couleur que vous pr�f�rez */
}


